<template>
  <div class="page-profile">
    <div class="container container--lg">
      <div class="page-profile__head">

        <h1 class="page-title">Помощь для близкого</h1>
        <p class="page-text">Укажите собственные данные</p>

      </div>
      <div class="row row--lg">
        <div class="col-12 col-md-4 col--lg">

          <NavSteps
            :list="stepList"
            :active="tab"
            @choose="changeTab($event)"
          />

        </div>
        <div class="col-12 col-md-8 col--lg">
          <template v-if="tab === 0">

            <DataPerson
              :valid.sync="steps.general.valid"
              :data.sync="steps.general.data"
            />

          </template>
          <template v-else-if="tab === 1">

            <DocumentsPerson
              :valid.sync="steps.documents.valid"
              :data.sync="steps.documents.data"
            />

          </template>
          <template v-else-if="tab === 2">

<!--        TODO: Сделать корректную валидацию для блока «Способ оплаты»   -->
            <PaymentMethod
              :active="paymentMethod"
              :list="PaymentList"
              @change="choosePayment"
              :valid.sync="steps.payment.valid"
            />

            <template v-if="paymentMethod === 'Банковская карта'">

              <PaymentCard
                :data.sync="steps.payment.data"
              />

            </template>

          </template>
          <template v-else-if="tab === 3">

            <DataRecipient
              :valid.sync="steps.familyGeneral.valid"
              :data.sync="steps.familyGeneral.data"
            />

          </template>
          <template v-else-if="tab === 4">

            <ServicesNeed
              :valid.sync="steps.help.valid"
              :data.sync="steps.help.data"
            />

          </template>
          <div class="page-profile__btn-gp">

            <template v-if="tab === 0">

              <router-link
                :to="{ name: 'ASSISTANCE_NEED_SELECT'}"
                class="btn btn--border"
              >Назад</router-link>

            </template>

            <template v-if="tab > 0">

              <button
                type="button"
                class="btn btn--border"
                @click="changeTab(tab - 1)"
              >Назад</button>

            </template>

            <template v-if="tab < 4">

              <button
                type="button"
                class="btn btn--prime"
                :disabled="!valid"
                @click="changeTab(tab + 1)"
              >Далее</button>

            </template>

            <template v-if="tab === 4">

              <button
                type="button"
                class="btn btn--prime"
                @click="submit"
                :disabled="loading || !validAllForms"
              >
                <template v-if="loading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </template>

                <template v-else>Завершить</template>
              </button>

            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import NavSteps from '@component/Module/NavSteps';
import DataPerson from '@component/Page/Profile/DataPerson';
import DataRecipient from '@component/Page/Profile/DataRecipient';
import DocumentsPerson from '@component/Page/Profile/DocumentsPerson';
import ServicesNeed from '@component/Page/Profile/ServicesNeed';
import PaymentMethod from '@component/Page/Profile/PaymentMethod';
import PaymentCard from '@component/Page/Profile/PaymentCard';
import confirmLeave from "@mixin/confirmLeave";

import { mapState } from 'vuex';
import stepList from "@mixin/Register/stepList";
import validation from "@mixin/Register/validation";

export default {
  components: {
    NavSteps,
    DataPerson,
    DataRecipient,
    DocumentsPerson,
    ServicesNeed,
    PaymentMethod,
    PaymentCard,
  },
  mixins: [confirmLeave, stepList, validation],
  data() {
    return {
      tab: 0,
      loading: false,
      currentForm: false,
      paymentMethod: '',
      PaymentList: [
        {
          value: 'Банковская карта',
          label: 'Банковская карта',
          icon: require('@img/icons/visa.svg'),
        },
        {
          value: 'Наличные',
          label: 'Наличные',
          icon: require('@img/icons/wallet.svg'),
        }
      ],
      country: {
        country: null,
        city: null,
        district: null,
        region: null
      },
      tabs: ['general', 'documents', 'payment', 'familyGeneral', 'help'],
      steps: {
        general: {
          valid: false,
          num: 0,
          title: 'Общие сведения о себе',
          data: {
            photo: null,
            first_name: '',
            last_name: '',
            gender: '',
            birthday: '',
            commentary: ''
          },
          key: 'general'
        },
        documents: {
          valid: false,
          num: 1,
          title: 'Мои документы',
          data: {
            serial: '',
            number: '',
            files: []
          },
          key: 'documents'
        },
        payment: {
          valid: false,
          num: 2,
          title: 'Способ оплаты',
          data: {
            number: '',
            estimate: '',
            cvv: ''
          },
          key: 'payment'
        },
        familyGeneral: {
          valid: false,
          num: 3,
          title: 'Добавить близкого',
          data: {
            first_name: '',
            last_name: '',
            gender: '',
            birthday: '',
            commentary: '',
            weight: '',
            reason: '',
            relation: '',
            photo: null,
            state: '',
            mobile: ''
          },
          key: 'familyGeneral'
        },
        help: {
          valid: false,
          num: 4,
          title: 'Какая помощь требуется',
          key: 'help',
          data: {
            services: []
          }
        },
      },
      testData: {
        general: {
          valid: false,
          num: 0,
          title: 'Общие сведения о себе',
          data: {
            photo: null,
            first_name: 'Дядя',
            last_name: 'Петя',
            gender: 'MALE',
            birthday: '2021-03-03',
            commentary: ''
          },
          key: 'general'
        },
        documents: {
          valid: false,
          num: 1,
          title: 'Мои документы',
          data: {
            serial: '1001',
            number: '101010',
            files: []
          },
          key: 'documents'
        },
        payment: {
          valid: false,
          num: 2,
          title: 'Способ оплаты',
          data: {
            number: '1000100010001000',
            estimate: '12/22',
            cvv: '012'
          },
          key: 'payment'
        },
        familyGeneral: {
          valid: false,
          num: 3,
          title: 'Добавить близкого',
          data: {
            first_name: 'Тетя',
            last_name: 'Катя',
            gender: 'FEML',
            birthday: '03.03.2010',
            commentary: '',
            weight: '100',
            reason: 'hz',
            relation: 'hz',
            address: 'какой-то адрес',
            photo: null,
            height: '123',
            state: 'SIS',
            mobile: '+79996666929'
          },
          key: 'familyGeneral'
        },
        help: {
          valid: false,
          num: 4,
          title: 'Какая помощь требуется',
          key: 'help',
          data: {
            services: []
          }
        },
      }
    };
  },

  mounted() {
    this.country = this.$route.query;

    if (this.$route.query._debug === '1') {
      this.$set(this, 'steps', this.testData);
    }
  },

  methods: {
    submitForm(formData, key) {
      this.steps[key].data = formData;
    },
    async changeTab(tab) {
      //TODO: Узнать сценарий отправки форм
      // if (this.tab === 3 && this.steps.familyGeneral.valid) {
      //   await this.createFamily().then(() => {
      //     this.tab = tab;
      //   }).catch(err => {});
      // } else {
      //   this.tab = tab;
      // }
      this.tab = tab;
    },
    createFamily() {
      return RequestManager.Family.create(this.user.id, this.steps.familyGeneral.data)
        .then(response => response)
        .catch(() => false);
    },
    choosePayment(value) {
      this.paymentMethod = value;
    },
    async submit() {

      this.loading = true;

      try {
        const sendUser = await RequestManager.Register.sendUser({
          general: this.steps.general.valid ? {
            ...this.steps.general.data,
            is_registered: true,
            need_help: true
          } : null,
          documents: this.steps.documents.valid ? this.steps.documents.data : null
        });

        let relative;

        if (this.steps.familyGeneral.valid) {
          relative = await this.createFamily();
        }

        if (relative) {
          sendUser.family_set.push(relative);
        }

        this.$store.commit('user/setUser', sendUser);

        this.$router.push({
          name: this.$routeName.ASSISTANCE_NEED_APPLICATIONS
        });
        //}
        //}

      }
      catch(err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

  },

  computed: {
    ...mapState('user', ['user']),

    valid() {
      return this.steps[this.tabs[this.tab]].valid;
    }

  },
};

</script>

<style lang="scss" scoped>

// page-profile
.page-profile {
  padding: 80px 0 30px;
  .page-profile__head {
    margin-bottom: 30px;
    @include respond-to('sm') {
      text-align: center;
    }
  }
  .page-profile__btn-gp {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    .btn {
      width: 100%;
      max-width: 343px;
      margin-bottom: 20px;
    }
  }
  @include respond-to('sm') {
    padding: 100px 0 30px;
    .page-profile__head {
      margin-bottom: 40px;
    }
     .page-profile__btn-gp {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
      .btn {
        width: 48%;
        max-width: 343px;
        margin-bottom: 0px;
      }
    }
  }
  @include respond-to('md') {
    padding: 120px 0 30px;
    .page-profile__head {
      margin-bottom: 50px;
    }
  }
}

</style>
